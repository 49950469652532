import facebookLogo from '@images/facebook_icon.png'
import googleLogo from '@images/google_icon.png'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useAuth } from '../../../contexts/AuthContext'
import { useMixPanel } from '../../../contexts/MixpanelContext'
import { useSearchContext } from '../../../contexts/SearchContext'
import { useSocialAuthMutation } from '../../../graphql/gqlHooks'
import useAmplitude from '../../../modules/amplitude/useAmplitude'
import Sentry from '../../../modules/sentry/sentry'
import config from '../../../util/config'
const SocialButton = dynamic(import('./SocialButton'), { ssr: false })

type SocialRedirectParams = {
    url: string
    queryStrings: Record<string, string>
}

const SocialLogin: React.FC<{ onAuthCb?: () => void }> = ({ onAuthCb }) => {
    const { setJwt, logOut } = useAuth()

    const router = useRouter()
    const { checkout } = useSearchContext()
    const { amplitudeInstance } = useAmplitude()
    const [auth, { loading, error }] = useSocialAuthMutation({
        onError(err) {
            logOut()
            if (onAuthCb) onAuthCb()
        },
        onCompleted({ socialAuth: jwt }) {
            amplitudeInstance.logEvent('social-login')
            setJwt(jwt)
            if (checkout.venueId) {
                router.push('/foglalas')
            } else {
                router.back()
            }
        },
    })

    const handleSocialLogin = (res) => {
        auth({
            variables: {
                input: { provider: res._provider, accessToken: res._token.accessToken },
            },
        })
    }

    const socialAuthHandler = (err) => {
        Sentry.captureException(err)
    }

    return (
        <div className="flex w-full mt-8 justify-evenly">
            <SocialButton
                onLoginSuccess={handleSocialLogin}
                onLoginFailure={socialAuthHandler}
                provider="google"
                appId={config.auth.googleClientId}
                scope={`https://www.googleapis.com/auth/userinfo.email`}
            >
                <div className="p-8 border border-gray-300 rounded-lg hover:bg-blue-100 hover:border-blue-600">
                    <div className="w-8 h-8">
                        <img src={googleLogo} alt="w-20 h-20 facebook login" />
                    </div>
                </div>
            </SocialButton>
            <SocialButton
                provider="facebook"
                appId={config.auth.facebookClientId}
                onLoginSuccess={handleSocialLogin}
                onLoginFailure={socialAuthHandler}
            >
                <div className="p-8 border border-gray-300 rounded-lg hover:bg-blue-100 hover:border-blue-600">
                    <div className="w-8 h-8">
                        <img src={facebookLogo} alt="w-20 h-20 facebook login" />
                    </div>
                </div>
            </SocialButton>
        </div>
    )
}

export default SocialLogin
